import React, { FunctionComponent } from 'react';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { ClickableElement } from '../../buttons';
import { RemoveIcon, AddIcon, DeleteBinIcon } from '../../svg/icons.component';
import { TextInput } from '../input/input.component';

const MAX_QUANTITY = 9999;
const MIN_QUANTITY = 1;
const COUNT_BUTTON_STYLE = 'w-third flex items-center justify-center pointer';

export type QuantityInputEventType = 'INCREMENT' | 'DECREMENT' | 'CHANGE' | 'BLUR' | 'ENTER';

export const QUANTITY_INPUT_STYLE_MAP = {
	DEFAULT: { background: '', color: 'theme-grey-darker', hover: 'hover-bg-theme-grey-light' },
	PRIMARY: { background: 'bg-theme-primary', color: 'theme-white', hover: 'hover-bg-theme-primary-darker' }
};

type QuantityStyle = keyof typeof QUANTITY_INPUT_STYLE_MAP;

export type QuantityInputProps = {
	automationHook?: string;
	label?: string;
	minQuantity?: number;
	maxQuantity?: number;
	defaultQuantity?: number; // Quantity to default to when quantity is invalid on blur
	quantity: number;
	setQuantity: (quantity: number, event: QuantityInputEventType) => void;
	disabled?: boolean;
	style?: QuantityStyle;
	canDelete?: boolean;
	isSettled?: boolean;
};

export const QuantityInput: FunctionComponent<QuantityInputProps> = ({
	automationHook,
	label = 'quantity input',
	minQuantity = MIN_QUANTITY,
	defaultQuantity = MIN_QUANTITY,
	quantity,
	setQuantity,
	disabled,
	style = 'DEFAULT',
	canDelete = false,
	isSettled,
	maxQuantity = MAX_QUANTITY
}) => {
	// #region Event Handlers
	const incrementQuantity = () => {
		if (quantity >= maxQuantity) {
			return setQuantity(maxQuantity, 'INCREMENT');
		}
		setQuantity(quantity + 1, 'INCREMENT');
	};

	const decrementQuantity = () => {
		if (quantity <= minQuantity) {
			return setQuantity(minQuantity, 'DECREMENT');
		}
		setQuantity(quantity - 1, 'DECREMENT');
	};

	const handleQuantityBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const value = parseInt(event.target.value, 10);
		// When we lose focus on the field and the value is invalid default it to 1
		setQuantity(!isNaN(value) ? Math.min(value, maxQuantity) : defaultQuantity, 'BLUR');
	};

	const handleQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
		// need to cache this quantity since setState is async another option is
		// using event.persist() could risk browser compatibility
		event.preventDefault();
		let newValue = Math.abs(parseInt(event.target.value, 10));
		if (!isNaN(newValue)) {
			// value should not be larger than MAX_QUANTITY
			newValue = Math.min(newValue, maxQuantity);
			// value should not be smaller than minQuantity
			newValue = Math.max(newValue, minQuantity);
			setQuantity(newValue, 'CHANGE');
		} else {
			// if value cannot parse an int set quantity to '' so users can still enter single digit number 1-9
			// TODO: EFDC-18417 - remove as any assertion
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			setQuantity('' as any, 'CHANGE');
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		event.preventDefault();
		if (event.key === 'Enter') {
			setQuantity(quantity, 'ENTER');
		} else if (event.key === 'ArrowUp') {
			incrementQuantity();
		} else if (event.key === 'ArrowDown') {
			decrementQuantity();
		}
	};
	// #endregion

	const chosenStyle = QUANTITY_INPUT_STYLE_MAP[style];

	return (
		<div className={`flex ba br2 justify-between theme-grey-light ${quantity > 0 ? '' : isSettled ? 'ba b--theme-error' : ''}`}>
			<ClickableElement
				ariaLabel="decrease quantity"
				className={`br2 br--left ${COUNT_BUTTON_STYLE} ${chosenStyle.color} ${chosenStyle.background} ${chosenStyle.hover}`}
				onClick={decrementQuantity}
				testId="decrease-quantity"
				automationHook={automationHook ? `${automationHook}-decrease` : undefined}
				disabled={disabled}>
				{canDelete && quantity === 1 ? (
					<DeleteBinIcon className={`f3 ${chosenStyle.color}`} />
				) : (
					<RemoveIcon className={`f3 ${chosenStyle.color}`} />
				)}
			</ClickableElement>
			<TextInput
				ariaLabel={label}
				testId="input-quantity"
				omitBaseClasses={true}
				className={`input-reset bn f5 w-third flex items-center tc input ${chosenStyle.background} ${chosenStyle.color} ${
					quantity > 0 ? '' : 'outline-0'
				}`}
				value={quantity}
				onBlur={handleQuantityBlur}
				onChange={handleQuantity}
				onKeyDown={handleKeys(['Enter', 'ArrowUp', 'ArrowDown'], handleKeyDown)}
				automationHook={automationHook}
				inputMode="numeric" // bring up numeric keyboard
				pattern="\d*" // REGEX: any number; by defining this pattern older iOS devices will bring up a numeric keyboard
				disabled={disabled}
			/>
			<ClickableElement
				ariaLabel="increase quantity"
				className={`br2 br--right ${chosenStyle.color} ${chosenStyle.background} ${chosenStyle.hover} ${COUNT_BUTTON_STYLE}`}
				onClick={incrementQuantity}
				testId="increase-quantity"
				automationHook={automationHook ? `${automationHook}-increase` : undefined}
				disabled={disabled}>
				<AddIcon className={`f3 ${chosenStyle.color}`} />
			</ClickableElement>
		</div>
	);
};

export type DynamicQuantityInputProps = {
	text: string;
} & QuantityInputProps;

export const DynamicQuantityInput: FunctionComponent<DynamicQuantityInputProps> = (props) => {
	const containerStyle: React.CSSProperties = { width: '150px', height: '46px', borderRadius: '5px' };

	return (
		<>
			{props.quantity === 0 ? (
				<div
					className="ba b--theme-primary-darker hover-b--theme-primary theme-primary-darker hover-theme-primary buttonsecondary"
					style={containerStyle}>
					<ClickableElement
						className="w-100 h-100 flex justify-center items-center"
						ariaLabel="open quantity selector"
						disabled={props.disabled}
						onClick={() => props.setQuantity(props.quantity + 1, 'INCREMENT')}>
						{props.text}
					</ClickableElement>
				</div>
			) : (
				<div style={containerStyle}>
					<QuantityInput {...props} minQuantity={0} />
				</div>
			)}
		</>
	);
};
